//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import {statusList} from '@/assets/js/statusInfo'
    export default {
        data () {
            return {
                wrapperHeight: 0,
                eventForm: {},
                statusList: statusList
            }
        },
        created() {
            let data = this.iStorage.get("demandDetails")
            this.statusList.forEach(item => {
                if (data.status == item.val) {
                    data.statusTxt = item.txt
                }
            })
            this.eventForm = data
        },
        watch: {
        },
        computed: {

        },
        mounted() {
            let body_h = this.iStorage.get('_screen_height') || document.documentElement.clientHeight || window.innerHeight;
            let header_h = document.getElementsByClassName('header_position')[0].clientHeight;
            this.wrapperHeight = body_h - 2 * header_h;
        },
        methods: {
            goBack () {
                this.iStorage.set("demand", '')
                this.$router.push('/')
            },
        }
    }
