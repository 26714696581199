export const statusList = [
    {
        val: -1,
        txt: '已取消'
    },
    {
        val: 0,
        txt: '未预约'
    },
    {
        val: 1,
        txt: '预约成功'
    },
    {
        val: 2,
        txt: '正在召开'
    },
    {
        val: 3,
        txt: '已结束'
    },
    {
        val: 4,
        txt: '已取消有损失'
    }
]
